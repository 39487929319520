<template>
    <v-card elevation="0" :dark="styles.dark" :style="{backgroundColor: styles.background, color: styles.backgroundTextColor, borderRadius: 0}">
        <powered-by class="mb-2" x-small :opacity="0.5"></powered-by>
        <v-snackbar
            top
            :value="error.show"
            color="error darken-1"
            left
            class="snackbar"
            :timeout="error.timeout"
        >
            {{error.message || 'Ups. Something went wrong.'}}
            <template v-slot:action="{ attrs }">
                <v-btn
                    dark
                    icon
                    v-bind="attrs"
                    @click="handleSnackbarClose"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <v-fade-transition>
            <div v-if="!submissionStatus">
                <component
                    :step="step"
                    :activeStep="step"
                    ref="step"
                    :is="step.component"
                    :survey="survey"
                    :contrastTextColor="styles.backgroundTextColor"
                    :dark="styles.dark"
                >
                    <template v-slot:[generateStepType(step.type)]>
                        <component
                            v-if="step.type !== 'description-slide'"
                            :is="generateStepType(step.type)"
                            :activeStep="step"
                            v-model="answer"
                            :contrastTextColor="styles.backgroundTextColor"
                            :dark="styles.dark"
                            :survey="survey"
                        />
                    </template>
                </component>
            </div>
        </v-fade-transition>
        <v-fade-transition>
            <div  v-if="submissionStatus" :style="`color: ${styles.backgroundTextColor}`" class="py-5">
                <v-scroll-y-reverse-transition hide-on-leave>
                    <div class="d-flex align-center" v-if="isLoading">
                        <v-progress-circular
                            :size="24"
                            indeterminate
                            :color="styles.backgroundTextColor"
                            class="mr-2"
                            width="2"
                        ></v-progress-circular>
    
                        <span>Submitting your answer...</span>
                    </div>
                </v-scroll-y-reverse-transition>
                <v-scroll-y-reverse-transition hide-on-leave>
                    <div>
                        <div v-if="isComplete" class="d-flex align-center">
                            <v-icon left>mdi-check</v-icon>
                            <span>Answer was submitted successfully</span>
                        </div>
                    </div>
                </v-scroll-y-reverse-transition>
            </div>
        </v-fade-transition>
    </v-card>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

import WelcomeStep from "../components/steps/WelcomeStep";
import InformationStep from '../components/steps/InformationStep';
import QuestionStep from "../components/steps/QuestionStep";
import FinishStep from "../components/steps/FinishStep";
import MultipleChoice from "../components/questions/MultipleChoice.vue";
import DatePicker from "../components/questions/DatePicker.vue";
import Checkbox from "../components/questions/Checkbox.vue";
import Email from "../components/questions/Email.vue";
import Rating from "../components/questions/Rating.vue";
import NetPromoterScore from "../components/questions/NetPromoterScore.vue";
import Name from "../components/questions/Name.vue";
import ZipCode from '../components/questions/ZipCode.vue';
import Yesno from "../components/questions/Yesno.vue";
import Dropdown from "../components/questions/Dropdown.vue";
import CustomField from "../components/questions/CustomField.vue";
import FileUpload from "../components/questions/FileUpload.vue";
import VoiceResponse from "../components/questions/VoiceResponse.vue";
import PhoneNumber from '../components/questions/PhoneNumber.vue'
import NumericInput from '../components/questions/NumericInput.vue'
import Matrix from '../components/questions/Matrix.vue';
import questionTypes from '@/configs/questionTypes';
import PoweredBy from '../components/PoweredBy.vue';

export default {
    name: 'SingleQuestionLayout',
    props: {
        step: {
            required: true
        }
    },
    components: {
        FinishStep,
        QuestionStep,
        InformationStep,
        WelcomeStep,
        MultipleChoice,
        DatePicker,
        Checkbox,
        Email,
        Rating,
        NetPromoterScore,
        Name,
        ZipCode,
        Yesno,
        Dropdown,
        CustomField,
        FileUpload,
        VoiceResponse,
        PhoneNumber,
        NumericInput,
        Matrix,
        PoweredBy,
    },
    computed: {
        ...mapGetters({
            styles: 'form/styles',
            survey: 'form/survey',
            steps: 'form/steps',
            answers: 'form/answers',
            activeStepNumber: 'form/activeStepNumber',
            error: 'form/error',
            submissionStatus: 'form/submissionStatus',
            passingVariables: 'form/passingVariables',
        }),
        answer: {
            get: function () {return this.answers && this.answers[this.step.id] && this.answers[this.step.id].answer},
            set: function (answerValue) {this.updateOrCreateAnswer(answerValue)},
        },
        isLoading(){
            return this.submissionStatus === 'loading'
        },
        isComplete(){
            return this.submissionStatus === 'complete'
        },
    },
    methods: {
        ...mapActions({
            updateOrCreateAnswer: 'form/updateOrCreateAnswer',
            submitAnswers: 'form/submitAnswers'
        }),

        generateStepType(type){
            if(type === questionTypes.VOICE_QUESTION){
                return questionTypes.VOICE_RESPONSE
            }
            return type
        },
        handleSnackbarClose(){
            this.setError({show: false})
        },
    },
    mounted(){
        // post message that question is ready
        window.parent.postMessage({type: 'voiceform.questionReady', questionId: this.step.id}, '*')
    }
}
</script>

<style lang="scss" scoped>
.snackbar{
    top: 62px;
}
</style>